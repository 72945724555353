<template>
  <div class="from_outer">
    <div>
      <el-form
        ref="formName"
        :rules="rules"
        label-width="250px"
        :model="formLabelAlign"
      >
        <el-row>
           <el-col :span="12">
            <el-form-item
              label="日期："
              :rules="[{ required: true, message: '请选择日期' }]"
            >
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="formLabelAlign.duty_date"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          </el-col>
          </el-row>
          <el-row v-for="(lead,index) in formLabelAlign.leader_userid" :key="lead.ding_userid">
            <el-col :span="8" >
                <el-form-item
                 label="值班领导："
                >
              <el-select
                v-model="formLabelAlign.leader_userid[index]['ding_userid']"
                filterable
                remote
                placeholder="请输入姓名查询"
                :remote-method="(query)=>getUserAndDept(query,{type:1})"
                :loading="loading"
                class="form-select"
                >
                <el-option
                  v-for="it in leaderUserList"
                  :key="it.id"
                  :label="it.nickname"
                  :value="it.ding_userid">
                  <span style="float: left">{{ it.nickname }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ it.department }}</span>
                </el-option>
              </el-select>
          </el-form-item>
          </el-col>
          <el-col :span="8">
              <el-form-item label="班次：">
                <el-select :value="time_list[3]" v-model="formLabelAlign.leader_userid[index]['time_id']" placeholder="请选择">
                  <el-option
                    v-for="itm in time_list"
                    :key="itm.id"
                    :label="itm.name"
                    :value="itm.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
        </el-row>
          <el-row v-for="(mid,index1) in formLabelAlign.middile_leaders" :key="mid.ding_userid">
            <el-col :span="8">
              <el-form-item label="中层值班：">
                <el-select
                v-model="formLabelAlign.middile_leaders[index1]['ding_userid']"
                filterable
                remote
                placeholder="请输入姓名查询"
                :remote-method="(query)=>getUserAndDept(query,{type:2})"
                :loading="loading"
                class="form-select"
                >
                <el-option
                  v-for="it in midUserList"
                  :key="it.id"
                  :label="it.nickname"
                  :value="it.ding_userid">
                  <span style="float: left">{{ it.nickname }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ it.department }}</span>
                </el-option>
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="班次：">
                <el-select :value="time_list[3]" v-model="formLabelAlign.middile_leaders[index1]['time_id']" placeholder="请选择">
                  <el-option
                    v-for="itm in time_list"
                    :key="itm.id"
                    :label="itm.name"
                    :value="itm.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
        </el-row>
          <el-row v-for="(dept,index2) in formLabelAlign.secondeDepartList" :key="index2">
            <el-col :span="8">
                <el-form-item label="值班部门:" prop="">
                  <el-select :value="dept.id" placeholder="请选择">
                  <el-option
                    v-for="(item,d) in formLabelAlign.secondeDepartList"
                    :key="d"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="值班人员："
              >
              <el-select
                v-model="formLabelAlign.secondeDepartList[index2]['ding_userid']"
                filterable
                remote
                placeholder="请输入姓名查询"
                :remote-method="(query)=>getUserAndDept(query,{type:3,ind:index2})"
                :loading="loading"
                class="form-select"
                >
                <el-option
                  v-for="it in formLabelAlign.secondeDepartList[index2].userList"
                  :key="it.id"
                  :label="it.nickname"
                  :value="it.ding_userid">
                  <span style="float: left">{{ it.nickname }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ it.department }}</span>
                </el-option>
              </el-select>
              </el-form-item> 
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="班次"
              >
                <el-select v-model="formLabelAlign.secondeDepartList[index2]['time_id']" placeholder="请选择">
                  <el-option
                    v-for="itm in time_list"
                    :key="itm.id"
                    :label="itm.name"
                    :value="itm.id">
                  </el-option>
                </el-select>
              </el-form-item> 
            </el-col>
        </el-row>
       
      </el-form>
      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary">{{
          type_name
        }}</el-button>
        <el-button @click="route_go">返回</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import {
  dutyPlanSaveInfo,
  dutyPlanSave,
  getUserAndDepartment,
} from "../../assets/request/api";
import Bepartment from "../../components/Bepartment";

export default {
  name: "student_redacy",
  components: {
    Bepartment,
  },
  data() {
    let phoneRuel = (rule, value, callback) => {
      if (!this.$tools.verify_phone(value)) {
        callback(new Error("请输入正确的手机号码"));
      } else {
        callback();
      }
    };
    return {
      type_name: "保存",
      //要修改的id
      id: "",
      formLabelAlign: {
        leader_userid:[{ding_userid:null,time_id:null}],
        duty_date:"",
        middile_leaders:[{ding_userid:null,time_id:null},{ding_userid:null,time_id:null}],
        secondeDepartList:[]
      },
      info:{},
      time_list:[],
      userList:[],
      leaderUserList:[],
      midUserList:[],
      loading:false,
      secondeDepartList:[],
      rules: {
        phone: [
          { validator: phoneRuel, trigger: "blur" },
          { required: true, message: "请输入用户手机号" },
        ],
      },
    };
  },
  created() {
    let { id } = this.$route.query;
    this.way_show(id);
  },
  methods: {
    way_show(id) {
      if (id) this.formLabelAlign.id = id;
      dutyPlanSaveInfo({ id }).then((res) => {
        let { info, timeList,leaderUserList,midUserList,userList} = res.data;
          this.time_list = timeList;
          this.info = info;
          let {secondeDepartList,middile_leaders,leader_userid} = info;
          this.formLabelAlign.secondeDepartList = secondeDepartList
          this.formLabelAlign.middile_leaders = middile_leaders
          this.formLabelAlign.leader_userid = leader_userid
          this.leaderUserList=leaderUserList?leaderUserList:[]
          this.midUserList=midUserList?midUserList:[]
          this.userList=userList?userList:[]
        if (id) {
          this.id = id;
          this.type_name = "修改";
          let { formLabelAlign } = this;
          let { duty_date,leader_userid,middile_leaders,secondeDepartList } =
            info;
          formLabelAlign.duty_date = duty_date;
        }
      });
    },
    getUserAndDept(query,par=null) {
        let params = {name:query}
        console.log(par,'par');
        let {type,ind} = par
        
        if(ind!=undefined)
          params.department_id = this.formLabelAlign.secondeDepartList[ind].id
          console.log(params,'para')
        // console.log(type,'type')
        if (query !== '') {
          this.loading = true;
          getUserAndDepartment(params).then((res)=>{
            this.loading = false;
            if(type==1)
             this.leaderUserList = res.data
            else if(type==2)
              this.midUserList =res.data;
            else if(type==3){
              let ulist =res.data
              this.formLabelAlign.secondeDepartList[ind]['userList'] = ulist
              console.log(this.formLabelAlign.secondeDepartList,'userList')
            }
              // this.userList =res.data;
          })
        } 
        // else {
        //   this.userList = [];
        // }
      },
    //点击保存
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let { formLabelAlign } = this;
          let middile_leaders = formLabelAlign.middile_leaders
          middile_leaders.map((item,index)=>{
            if(!item.ding_userid||!item.time_id)
            middile_leaders.splice(index)
          })
          formLabelAlign.middile_leaders= middile_leaders;
          let secondeDepartList =formLabelAlign.secondeDepartList
          let deparment_list =[]
          secondeDepartList.map((item,index)=>{
            if(item.ding_userid&&item.time_id&&item.id)
              deparment_list.push({department_id:item.id,ding_userid:item.ding_userid,time_id:item.time_id})
          })
          formLabelAlign.secondeDepartList =deparment_list;
          dutyPlanSave(formLabelAlign).then((res) => {
            if (res.err_code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              setTimeout(function () {
                that.$router.go(-1);
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    //返回
    route_go() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.inquire-sty {
  background: #4f7afd;
}
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 150px;
}
.pop-inp {
  width: 150px;
}
.tag-sty {
  width: 150px;
  display: flex;
  flex-direction: column;
}
.selectlength {
  color: #515a6e;
  border: 1px solid #abdcff;
  background-color: #f0faff;
  padding: 10px 20px;
  font-size: 14px;
  margin: 20px 0;
  border: 5px;
  span {
    color: rgb(45, 140, 240);
  }
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.botton-an {
  text-align: center;
  margin-top: 10px;
}
.pop-img {
  margin: 0 5px 10px 0;
  width: 40px;
  height: 40px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
</style>